import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { update_statement_detail_detail_by_statement_by_group } from "../../slices/statementSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { amountFormatWithCurrencySymbol } from "../../../modUtils/functions/numberFormat";

export default function PickupDetails({ statementId, detailId, onClose }) {
  const { t } = useTranslation();
  const { groupId } = useSelector((state) => state.manager);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const { statements } = useSelector((state) => state.statement);
  const formatDate = "DD/MM/YYYY HH:mm";
  const [error, setError] = useState("");

  useEffect(() => {
    //get current statement
    const currentStatement = statements.find(
      (statement) => statement.id === statementId
    );
    if (currentStatement) {
      const currentDetail = currentStatement.details.find(
        (detail) => detail.id === detailId
      );
      if (currentDetail) {
        const difference = getStatementDifference(
          currentDetail.amount_cashbox,
          currentDetail.amount_cashbox_counted
        );
        if (difference !== 0) {
          setIsChecked(false);
        } else setIsChecked(true);
        setAmount(currentDetail.amount_cashbox_counted || "");
      }
    }
  }, [statements, statementId, detailId]);
  const updateStatements = async () => {
    setLoading(true);
    try {
      await dispatch(
        update_statement_detail_detail_by_statement_by_group({
          groupId: groupId,
          statementId,
          detailId,
          amountCashboxCounted: amount,
        })
      ).unwrap();
      //refresh
      onClose();
    } catch (error) {
      setError("le champ du montant ne peut pas être vide");
    } finally {
      setLoading(false);
    }
  };
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };
  const validateUpdateStatement = async () => {
    await updateStatements();
  };
  const getStatementDifference = (amountCashbox, amountCashboxCounted) => {
    const differenceAmount = amountCashboxCounted - amountCashbox;

    return differenceAmount;
  };
  const handleCheckedBoxChange = (e) => {
    //isChecked de type boolean 
    const value = e.target.value === "true";
    const isCheckedValue = value;
    setIsChecked(isCheckedValue);
    const currentStatement = statements.find(
      (statement) => statement.id === statementId
    );
    if (currentStatement) {
      const currentDetail = currentStatement.details.find(
        (detail) => detail.id === detailId
      );
      //si la selection "correspondance" => montant compté prend la valeur du relevé
      if (isCheckedValue) {
        setAmount(currentDetail?.amount_cashbox || "");
      }
      // si 'not correspondance' => montant compté sera modifiable avec un affichage par défaut de la valeur de l api
      else {
        setAmount(currentDetail?.amount_cashbox_counted || "");
      }
    }
  };

  return (
    <>
      <div className="flex  flex-col z-10 absolute bg-white w-96 h-80 right-4 p-4 mt-2 rounded shadow-md">
        <div className="p-2.5">
          <div className="flex flex-row justify-between items-start mb-5">
            <div>
              <div className="uppercase font-bold text-secondary-color text-[17px] leading-[20px] tracking-[0.4px]">
                {t("pickupDetails.statementDetails")}
              </div>
              {statements?.map((statement) => (
                <div
                  key={statement.id}
                  className="text-left font-semibold text-[#4B4B4B] text-[10px] leading-[20px]"
                >
                  {statementId === statement.id && (
                    <>
                      {" "}
                      {t("pickupDetails.statementDate")}
                      {moment(statement.date).format(formatDate)}
                    </>
                  )}
                </div>
              ))}
            </div>
            <div className="font-semibold items-end text-right text-[#4B4B4B] text-[11px] leading-[15px]">
              {" "}
              {t("pickupDetails.identifier")}{detailId}
            </div>
          </div>
          <div className="flex flex-col justify-between items-start	">
            <div>
              <input
                type="radio"
                name="amountMatch"
                value="true"
                checked={isChecked}
                onChange={handleCheckedBoxChange}
              />
              <label
                className="font-medium ml-2 leading-[15px] text-xs text-[#338EF5] text-left"
                for="correspondance"
              >
                {t("pickupDetails.amountMatch")}
              </label>
            </div>

            <div className="mt-2">
              <input
                type="radio"
                name="amountMatch"
                value="false"
                checked={!isChecked}
                onChange={handleCheckedBoxChange}
              />
              <label
                className="font-medium ml-2 leading-[15px] break-all text-xs text-[#338EF5] text-left"
                for="noCorrespondance"
              >
                {t("pickupDetails.amountNotMatch")}
              </label>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center mt-4">
            <div className="w-32">
              <table>
                <thead>
                  <th>{t("table.tabColAmountCashbox")}</th>
                </thead>
                <tbody>
                  {statements?.map((statement) =>
                    statementId === statement.id
                      ? statement.details
                          ?.filter((detail) => detail.id === detailId)
                          .map((detail) => (
                            <tr key={detail.id}>
                              <td className="bg-[#40adff] text-white">
                                {" "}
                                {detail?.amount_cashbox == null
                                  ? "-"
                                  : amountFormatWithCurrencySymbol(
                                      detail?.amount_cashbox
                                    )}
                              </td>
                            </tr>
                          ))
                      : null
                  )}
                </tbody>
              </table>
            </div>
            <div className="w-32">
              <table>
                <thead>
                  <th>{t("table.tabColAmountCashboxCounted")}</th>
                </thead>

                <tbody>
                  {statements?.map((statement) =>
                    statementId === statement.id
                      ? statement.details
                          ?.filter((detail) => detail.id === detailId)

                          .map((detail) => (
                            <tr key={detail.id}>
                              <td>
                                {isChecked ? (
                                  <span>
                                    {amountFormatWithCurrencySymbol(
                                      detail?.amount_cashbox
                                    )}
                                  </span>
                                ) : (
                                  <input
                                    type="number"
                                    value={amount}
                                    onChange={handleAmountChange}
                                    className="border border-none focus:outline-none p-1 w-full"
                                  />
                                )}
                              </td>
                            </tr>
                          ))
                      : null
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center mt-4">
          {error && (
            <div className="text-red-500 items-center justify-center text-xs mt-2">
              {error}
            </div>
          )}
          <button
            type="button"
            className={`
                       w-28 h-10 bg-[#36C31D] rounded-[3px] font-bold text-[13px]
                        leading-[78px] text-center uppercase text-white flex border-none items-center justify-center
                    `}
            onClick={validateUpdateStatement}
          >
            {t("common.transButtonValidate")}
          </button>
        </div>
      </div>
    </>
  );
}
