import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LeftSiderBar from "ck_commun/src/app/modUtils/components/leftSideBar/LeftSideBar";
import { hasPermission } from "ck_commun/src/app/modUtils/functions/handlePermissions";
import { logout } from "ck_commun/src/app/modUtils/functions/logout";

import "./LeftSideBar.scss";

export default function LeftSideBar({ collapsed }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const srcImg = process.env.PUBLIC_URL + "/img/menuIcons/";
  const appLogo = process.env.PUBLIC_URL + "/laundryLogoSquare.png";
  const isProd = process.env.NODE_ENV === "production";
  const { selectedGroup } = useSelector((state) => state.manager);

  const permissions = selectedGroup?.permissions;
  const groupPermissions = selectedGroup?.plan?.permissions;



  // Data pour generer le menu de la leftSideBar
  const menuData = [
    {
        icon: <img src={srcImg + "Accueil.svg"} alt=""/>,
        itemKey: "home",
        label: t("menu.transHome"),
        prod_visible: true
    },
    {
        icon: <img src={srcImg + "Equipement.svg"} alt=""/>,
        itemKey: "equipment",
        label: t("menu.transEquipment"),
        submenus: [
            {
                itemKey: "equipment/sites-equipments",
                label: t("menu.transSitesAndEquipment"),
                prod_visible: true
            },
            {
                itemKey: "equipment/sites-map",
                label: t("menu.transSitesMap"),
                prod_visible: true
            },
            {
                itemKey: "equipment/equipments-shared",
                label: t("menu.transEquipmentsShared"),
                prod_visible: true,
                permission: hasPermission('mod_equipment_shared:can_access', permissions),
                groupPermission: hasPermission('mod_equipment_shared:can_access', groupPermissions)
            },
        ],
        prod_visible: true
    },
    {
        icon: <img src={srcImg + "Activite.svg"} alt=""/>,
        itemKey: "activity",
        label: t("menu.transActivities"),
        permission: hasPermission('mod_cumulative:show_statistic', permissions),
        groupPermission: hasPermission('mod_cumulative:show_statistic', groupPermissions),
        submenus: [
            {
                itemKey: "activity/dashboard",
                label: t("menu.transActDashboard"),
                permission: hasPermission('mod_cumulative:show_statistic', permissions),
                groupPermission: hasPermission('mod_cumulative:show_statistic', groupPermissions),
                prod_visible: true
            },
            {
                itemKey: "activity/ca",
                label: t("menu.transActCA"),
                permission: hasPermission('mod_cumulative:show_statistic', permissions),
                groupPermission: hasPermission('mod_cumulative:show_statistic', groupPermissions),
                prod_visible: true
            },
            {
                itemKey: "activity/statistics",
                label: t("menu.transActStatistics"),
                permission: hasPermission('mod_cumulative:show_statistic', permissions),
                groupPermission: hasPermission('mod_cumulative:show_statistic', groupPermissions),
                prod_visible: false
            },
            {
                itemKey: "activity/marketing",
                label: t("menu.transActMarketing"),
                permission: hasPermission('mod_cumulative:show_statistic', permissions),
                groupPermission: hasPermission('mod_cumulative:show_statistic', groupPermissions),
                prod_visible: false
            },
            {
                itemKey: "activity/reporting",
                label: t("menu.transActReportings"),
                permission: hasPermission('mod_cumulative:show_statistic', permissions),
                groupPermission: hasPermission('mod_cumulative:show_statistic', groupPermissions),
                prod_visible: false
            }
        ],
        prod_visible: true
    },
    {
        icon: <img src={srcImg + "Historique.svg"} alt=""/>,
        itemKey: "history",
        label: t("menu.transHistory"),
        permission: hasPermission('mod_sale:show_sale', permissions),
        groupPermission: hasPermission('mod_sale:show_sale', groupPermissions),
        submenus: [
            {
                itemKey: "history/sales",
                label: t("menu.transHistSales"),
                permission: hasPermission('mod_sale:show_sale', permissions),
                groupPermission: hasPermission('mod_sale:show_sale', groupPermissions),
                prod_visible: true
            },
            {
                itemKey: "history/telecollects",
                label: t("menu.transHistTelecollects"),
                permission: hasPermission('mod_sale:show_sale', permissions),
                groupPermission: hasPermission('mod_sale:show_sale', groupPermissions),
                prod_visible: true
            },
            {
                itemKey: "history/pickups",
                label: t("menu.transHistStatements"),
                permission: hasPermission('mod_statement:show_statement', permissions),
                groupPermission: hasPermission('mod_statement:show_statement', groupPermissions),
                prod_visible: true
            },
            {
                itemKey: "history/logs",
                label: t("menu.transHistLogs"),
                permission: hasPermission('mod_sale:show_sale', permissions),
                groupPermission: hasPermission('mod_sale:show_sale', groupPermissions),
                prod_visible: false

                
            }
        ],
        prod_visible: true
    },
    {
        icon: <img src={srcImg + "BadgeCle.svg"} alt=""/>,
        itemKey: "badges",
        label: t("menu.transBadgesAndKeys"),
        permission: hasPermission('mod_wallet_local:manage_support', permissions),
        groupPermission: hasPermission('mod_wallet_local:manage_support', groupPermissions),
        submenus: [
            {
                itemKey: "badges/dashboard",
                label: t("menu.transBadgesHome"),
                permission: hasPermission('mod_wallet_local:manage_support', permissions),
                groupPermission: hasPermission('mod_wallet_local:manage_support', groupPermissions),
                prod_visible: false
            },
            {
                itemKey: "badges/subscription",
                label: t("menu.transBadgesSubscription"),
                permission: hasPermission('mod_wallet_local:manage_support', permissions),
                groupPermission: hasPermission('mod_wallet_local:manage_support', groupPermissions),
                prod_visible: true
            },
            {
                itemKey: "badges/statistics",
                label: t("menu.transBadgesStatistics"),
                permission: hasPermission('mod_wallet_local:manage_support', permissions),
                groupPermission: hasPermission('mod_wallet_local:manage_support', groupPermissions),
                prod_visible: false
            }
        ],
        prod_visible: true
    },
    {
        icon: <img src={srcImg + "Promo.svg"} alt=""/>,
        itemKey: "promo",
        label: t("menu.transPromo"),
        submenus: [
            {
                itemKey: "promo/dashboard",
                label: t("menu.transPromoDashboard"),
                prod_visible: false
            },
            {
                itemKey: "promo/promotions",
                label: t("menu.transPromotions"),
                prod_visible: false
            },
            {
                itemKey: "promo/voucher",
                label: t("menu.transVoucher"),
                prod_visible: false
            }
        ],
        prod_visible: false
    },
    {
        icon: <img src={srcImg + "CRM.svg"} alt=""/>,
        itemKey: "crm",
        label: t("menu.transCRM"),
        permission: hasPermission('mod_entity:manage_entity', permissions),
        groupPermission: hasPermission('mod_entity:manage_entity', groupPermissions),
        submenus: [
            {
                itemKey: "crm/dashboard",
                label: t("menu.transCrmDashboard"),
                permission: hasPermission('mod_entity:manage_entity', permissions),
                groupPermission: hasPermission('mod_entity:manage_entity', groupPermissions),
                prod_visible: false
            },
            {
                itemKey: "crm/clients",
                label: t("menu.transCrmClients"),
                permission: hasPermission('mod_entity:manage_entity', permissions),
                groupPermission: hasPermission('mod_entity:manage_entity', groupPermissions),
                prod_visible: false
            },
            {
                itemKey: "crm/profiles",
                label: t("menu.transCrmProfiles"),
                permission: hasPermission('mod_entity:manage_entity', permissions),
                groupPermission: hasPermission('mod_entity:manage_entity', groupPermissions),
                prod_visible: false
            },
            {
                itemKey: "crm/marketing",
                label: t("menu.transCrmMarketing"),
                permission: hasPermission('mod_entity:manage_entity', permissions),
                groupPermission: hasPermission('mod_entity:manage_entity', groupPermissions),
                prod_visible: false
            },
            {
                itemKey: "crm/facturation",
                label: t("menu.transCrmFacturation"),
                permission: hasPermission('mod_entity:manage_entity', permissions),
                groupPermission: hasPermission('mod_entity:manage_entity', groupPermissions),
                prod_visible: false
            },
            {
                itemKey: "crm/factures",
                label: t("menu.transCrmFactures"),
                permission: hasPermission('mod_entity:manage_entity', permissions),
                groupPermission: hasPermission('mod_entity:manage_entity', groupPermissions),
                prod_visible: false
            }
        ],
        prod_visible: false
    },
    {
        icon: <img src={srcImg + "Boutique.svg"} alt=""/>,
        itemKey: "store",
        label: t("menu.transStore"),
        prod_visible: false
    },
    {
        icon: <img src={srcImg + "Parametre.svg"} alt=""/>,
        itemKey: "settings",
        label: t("menu.transSettings"),
        submenus: [
            {
                itemKey: "settings/account",
                label: t("menu.transSettingsAccount"),
                prod_visible: true
            },
            {
                itemKey: "settings/access-list",
                label: t("menu.transSettingsAccessList"),
                permission: hasPermission('mod_manager:manage_company', permissions),
                groupPermission: hasPermission('mod_manager:manage_company', groupPermissions),
                prod_visible: false
            },
            {
                itemKey: "settings/access-group",
                label: t("menu.transSettingsAccessGroup"),
                permission: hasPermission('mod_manager:manage_company', permissions),
                groupPermission: hasPermission('mod_manager:manage_company', groupPermissions),
                prod_visible: false
            },
            {
                itemKey: "settings/alerts",
                label: t("menu.transSettingsAlerts"),
                permission: hasPermission('mod_manager:manage_company', permissions),
                groupPermission: hasPermission('mod_manager:manage_company', groupPermissions),
                prod_visible: false
            },
            {
                itemKey: "settings/widgets",
                label: t("menu.transSettingsWidgets"),
                permission: hasPermission('mod_manager:manage_company', permissions),
                groupPermission: hasPermission('mod_manager:manage_company', groupPermissions),
                prod_visible: false
            }
        ],
        prod_visible: true
    },
    {
        icon: <img src={srcImg + "Deconnexion.svg"} alt=""/>,
        itemKey: "/",
        label: t("menu.transLogout"),
        onClick: () => logout(),
        prod_visible: true
    }
];


  // fonction pour generer le menu de prod
  const ProdMenuGet = (items) => {
    return items
      .filter((item) => item.prod_visible)
      .map((item) => ({
        ...item,
        submenus: item.submenus ? ProdMenuGet(item.submenus) : undefined,
      }));
  };

  const menuItems = isProd ? ProdMenuGet(menuData) : menuData;

  return (
    <LeftSiderBar
      collapsed={collapsed}
      menuItems={menuItems}
      appName={"laundry"}
      applogo={appLogo}
    />
  );
}
